<template>
  <div class="iniciolaason">
    <HeaderSec />
    <swiper ref="mySwiper" :options="swiperOption">
      <swiper-slide>
        <div class="bloqueuno">
          <h1><span>Análisis</span> microbiológicos</h1>
          <p>
            Análisis microbiológicos y control de calidad para materia prima...
          </p>
          <a href="/laason/servicios/#idB2">Ver más</a>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="bloqueunoS2">
          <h1><span>Nuestros</span> servicios</h1>
          <p>
            Análisis microbiológicos y control de calidad para materia prima,
            producto terminado, bebidas, agua, superficies vivas e inertes así
            como ambientes
          </p>
          <a href="/laason/servicios">Ver más</a>
        </div>
      </swiper-slide>
    </swiper>

    <div class="bloquedos">
      <div class="displacementPoint" slot="pagination"></div>
      <div class="filauno-bd">
        <h6>Servicios</h6>
        <h2>Esto es lo que hacemos</h2>
      </div>
      <div class="filados-bd">
        <div class="coluno-bd">
          <div class="bloserv">
            <img class="C1IMG1" src="../assets/icouno.png" alt="" />
            <div class="blotxtserv">
              <p class="C1T1">
                Análisis microbiológico de alimentos, aguas, superficios y
                ambientes
              </p>
              <a href="https://laason.mx/laason/servicios">Ver más</a>
            </div>
          </div>
          <div class="bloserv">
            <img class="C1IMG2" src="../assets/icodos.png" alt="" />
            <div class="blotxtserv">
              <p class="C1T2">
                Análisis fisicoquímicos de agua potable y residual
              </p>
              <a href="https://laason.mx/laason/servicios">Ver más</a>
            </div>
          </div>
        </div>

        <div class="coldos-bd">
          <div class="bloserv">
            <img class="C2IMG1" src="../assets/icotres.png" alt="" />
            <div class="blotxtserv">
              <p class="C2T1">
                Etiquetado nutrimental México y FDS- California
              </p>
              <a href="https://laason.mx/laason/servicios">Ver más</a>
            </div>
          </div>
          <div class="bloserv">
            <img class="C2IMG2" src="../assets/icocuatro.png" alt="" />
            <div class="blotxtserv">
              <p class="C2T2">
                Análisis clínicos para manipuladores de alimentos
              </p>
              <a href="https://laason.mx/laason/servicios">Ver más</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="nosotros" class="bloquetres">
      <div class="coluno-bt">
        <img src="../assets/imgb3.png" alt="" />
      </div>
      <div class="coldos-b3">
        <div class="filauno-bt">
          <h6>Sobre nosotros</h6>
          <h2>Estamos a tu servicio</h2>
        </div>
        <div class="filados-bt">
          <div class="colunof2b3">
            <h3>Misión</h3>
            <p>
              Laboratorio de Análisis de Alimentos y Aguas de Sonora S.A de
              C.V., es una empresa que busca fortalecer el sistema de salud de
              nuestra región, a la vez que contribuye con sus clientes al
              cumplimiento de normativas enfocadas a elevar la calidad de sus
              productos alimenticios, mediante asesoría y servicios
              especializados con altos estándares calidad y de responsabilidad
              social.
            </p>
            <pre>
Laboratorio de Análisis de Alimentos
y Aguas de Sonora S.A de C.V., es
una empresa que busca fortalecer el
sistema de salud de nuestra región,
a la vez que contribuye con sus
clientes al cumplimiento de
normativas enfocadas a elevar la
calidad de sus productos
alimenticios, mediante asesoría y
servicios especializados con altos
estándares calidad y de
responsabilidad social.</pre>
          </div>
          <div class="coldosf2b3">
            <h3>Visión</h3>
            <p>
              Ser el laboratorio de análisis de alimentos y aguas con mayor
              penetración en la industria alimentaria de la región, siempre
              comprometidos con la responsabilidad social y el desarrollo
              sustentable de nuestro entorno.
            </p>
            <div class="filai-f2b3">
              <h4>Valores</h4>
              <div class="blolista-f2b3">
                <div class="listauno-f2b3">
                  <ul>
                    <li>Calidad</li>
                    <li>Responsabilidad</li>
                    <li>Honestidad</li>
                    <li>Respeto</li>
                    <li>Discreción</li>
                    <li>Amabilidad</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="acreditaciones" class="bloquecuatro">
      <!-- <div class="filauno-b4">
        <div class="coluno-f1b4">
          <h6>Certificaciones</h6>
          <h2>Calidad y seguridad ante todo</h2>
          <img src="../assets/esrlogo.png" alt="" />
          <p>
            Siempre comprometidos con la responsabilidad social y el desarrollo
            sustentable de nuestro entorno, Laboratorio de Alimentos y Aguas de
            Sonora poco a poco se ha posicionado dentro de los laboratorios de
            alimentos con mayor penetración en la industria alimentaria de
            Hermosillo y otras ciudades del estado de Sonora, México.
          </p>
          <pre>
Siempre comprometidos con la
responsabilidad social y el desarrollo
sustentable de nuestro entorno, Laboratorio de
Alimentos y Aguas de Sonora poco a poco se
ha posicionado dentro de los laboratorios de
alimentos con mayor penetración en la
industria alimentaria de Hermosillo y otras
ciudades del estado de Sonora, México.</pre>
          <a @click="showmodal = !showmodal" target="_blank">Ver certificado</a>
          <div v-if="showmodal == true" class="modalss">
            <LaasonModal @listenModal="closeModal" />
          </div>
        </div>
        <div class="coldos-f1b4">
          <img src="../assets/imgesr.png" alt="" />
        </div>
      </div> -->
      <p>Acreditaciones</p>
      <h3>Calidad y seguridad ante todo</h3>

      <div>
        <button @click="showmodal = !showmodal, selected = acreditacionUno">
          <p>Laboratorio acreditado ISO/IEC 17025:2017 por Perry Johnson</p>
          <img src="@/assets/eye.png" alt="ver acreditación">
        </button>
        <hr>
        <button @click="showmodal = !showmodal, selected = acreditacionUno">
          <p>Laboratorio acreditado en el Programa LAAF <br> (Laboratory Accreditation <br> for Analyses of Food) de FDA
          </p>
          <img src="@/assets/eye.png" alt="ver acreditación">
        </button>
        <hr>
        <button @click="showmodal = !showmodal, selected = acreditacionDos">
          <p>Laboratorio acreditado NMX-EC-17025-IMNC-2018 / ISO/IEC 17025:2017 por Mexicana de Acreditación</p>
          <img src="@/assets/eye.png" alt="ver acreditación">
        </button>
      </div>

      <img class="acreditacion__logo" src="../assets/esrlogo.png" alt="logo">

      <div v-if="showmodal == true" class="modalss">
        <div class="backdrop" @click="closeModal(false)"></div>
        <LaasonModal @listenModal="closeModal" :acreditacion="selected" />
      </div>

    </div>




    <!--<div class="bloquecinco">
            <img src="../assets/labfisico.png" alt="">
            <div class="colform">
                <h2>¿Dudas? Mándanos un mensaje.</h2>
            </div>
        </div>-->
    <div id="contacto" class="Bloque12">
      <div class="B12B1">
        <img src="../assets/Rectangle_25P.png" />
      </div>

      <div class="B12_Esp">
        <div class="B12_F1">
          <p>¿Dudas? Mándanos un mensaje.</p>
        </div>
        <form @submit.prevent="enviarMail(name, phone, email, message)">
          <div class="B12_F2">
            <label for="Nombre">Nombre</label>
            <input v-model="name" name="Nombre" />
          </div>
          <div class="B12_F2">
            <label for="Telefono">Télefono</label>
            <input v-model="phone" name="Telefono" />
          </div>
          <div class="B12_F2">
            <label for="Correo">Correo</label>
            <input v-model="email" name="Correo" />
          </div>
          <div class="B12_F5">
            <label for="Mensaje">Mensaje</label>
            <textarea v-model="message" name="Mensaje"></textarea>
          </div>
          <div class="B12_F6">
            <button type="submit">Enviar</button>

            <div v-if="this.status == 'success'">
              <p class="cart_msg cart_success">{{ this.msg }}</p>
            </div>
            <div v-if="this.status == 'error'">
              <p class="cart_msg cart_error">{{ this.msg }}</p>
            </div>
          </div>
        </form>
        <div class="B12_F7">
          <p><span>Tels.</span> (662) 213.23.02 y (662) 313.06.50.</p>
          <p><span>Mail.</span> administracion@laason.mx</p>
          <p>
            <span>Dirección:</span> Garmendia N°80, Col.San Benito C.P. 83190.
            Hermosillo, Sonora.
          </p>
        </div>
        <div class="EB12_F7">
          <div class="Eb12_F1">
            <p><span>Tels.</span> (662) 213.23.02 y (662) 313.06.50.</p>
            <p><span>|</span></p>
            <p><span>Mail.</span> administracion@laason.mx</p>
          </div>
          <p>
            <span>Dirección:</span> Garmendia N°80, Col.San Benito C.P. 83190.
            Hermosillo, Sonora.
          </p>
        </div>
      </div>
    </div>
    <div class="bloqueseis">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3486.378370506103!2d-110.95978618472249!3d29.094508882237868!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86ce850526e3f02f%3A0xbbb1453832ac2b5b!2sLaboratorio%20de%20Alimentos%20y%20Aguas%20de%20Sonora%20S.A.%20de%20C.V.!5e0!3m2!1ses!2smx!4v1638914584188!5m2!1ses!2smx"
        width="100%" height="400" style="border: 0" allowfullscreen="" loading="lazy"></iframe>
    </div>
    <FooterSec :name="this.opc" />
  </div>
</template>
<script>
import FooterSec from "./FooterSec.vue";
import LaasonModal from "./modal/LaasonModal.vue";
import HeaderSec from "./HeaderSec.vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  name: "Homelaason",
  components: {
    FooterSec,
    LaasonModal,
    Swiper,
    SwiperSlide,
    HeaderSec,
  },

  data() {
    return {
      opc: "laason",
      showmodal: false,
      modalopc: "",
      name: "",
      phone: "",
      email: "",
      message: "",
      status: "",
      msg: "",
      swiperOption: {
        slidesPerView: 1,
        spaceBetween: 1,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },

      selected: null,
      acreditacionUno: [require('@/assets/cert/acreditacion_1.png'), require('@/assets/cert/acreditacion_2.png'), require('@/assets/cert/acreditacion_3.png')],
      acreditacionDos: [require('@/assets/cert/acreditacion_4.png'), require('@/assets/cert/acreditacion_5.png'),],
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  mounted() {
    console.log("Current Swiper instance object", this.swiper);
    this.swiper.slideTo(0, 7, false);
  },
  methods: {
    closeModal(value) {
      this.showmodal = value;
    },
    mostrarModal(opcion) {
      this.showmodal = opcion;
      console.log(this.showmodal);
    },

    enviarMail: async function (name, phone, email, message) {
      this.msg = "";
      this.status = "";

      if (name == undefined || name == "" || name == null) {
        this.msg = "El campo nombre no es valido.";
        this.status = "error";
      } else if (phone == undefined || phone == "" || phone == null) {
        this.msg = "El campo medio no es valido.";
        this.status = "error";
      } else if (email == undefined || email == "" || email == null) {
        this.msg = "El campo correo no es valido.";
        this.status = "error";
      } else if (message == undefined || message == "" || message == null) {
        this.msg = "El campo comentarios no es valido.";
        this.status = "error";
      } else {
        let data = {
          name: name,
          phone: phone,
          email: email,
          mensaje: message,
          Tel_Empresa: "(662) 213.23.02 y (662) 313.06.50.",
          Mail_Empresa: "administracion@laason.mx",
          Direc_Empresa:
            "Garmendia N°80, Col.San Benito C.P. 83190. Hermosillo, Sonora.",
        };
        let response = await this.$store.dispatch(
          "admin/enviarMailContacto",
          data
        );

        if (response.status == "success") {
          this.status = "success";
          this.msg = "Mensaje enviado con exito.";
          this.name = "";
          (this.phone = ""), (this.email = ""), (this.message = "");
        } else {
          this.status = "error";
          this.msg =
            "Lo sentimos ha ocurrido un error al intentar entregar tu mensaje.";
        }
      }
    },
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap");

.displacementPoint {
  text-align: center;
}

.displacementPoint span {
  margin: 0vw 0.2vw;
}

.modalss {
  position: fixed;
  z-index: 1;
  padding-top: 10.5vw;
  inset: 0;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.bloqueuno {
  background-image: url("../assets/banneruno.png");
  background-size: contain;
  height: 31.6vw;
}

.bloqueuno {
  padding-top: 8.072916666666668vw;
}

.bloqueuno h1 {
  width: 30vw;
  height: 10vw;
  color: white;
  font-weight: 800;
  font-size: 4.166666666666666vw;
  line-height: 4.541666666666667vw;
  margin-left: 10.208333333333334vw;
}

.bloqueuno span {
  color: #6fba91;
}

.bloqueuno p {
  font-size: 0.8333333333333334vw;
  color: white;
  margin-left: 10.208333333333334vw;
  margin-bottom: 3vw;
}

.bloqueuno a {
  font-size: 0.6770833333333334vw;
  color: white;
  padding: 1.1vw 3.9vw;
  background: linear-gradient(to left, #6fba91 100%, #76dfa6 100%);
  border-radius: 8vw;
  margin-left: 10.208333333333334vw;
}

.bloqueunoS2 {
  background-image: url("../assets/servicioL.svg");
  background-size: contain;
  height: 31.6vw;
  padding-top: 8.072916666666668vw;
}

.bloqueunoS2 h1 {
  width: 30vw;
  height: 10vw;
  color: white;
  font-weight: 800;
  font-size: 4.166666666666666vw;
  line-height: 4.541666666666667vw;
  margin-left: 10.208333333333334vw;
}

.bloqueunoS2 span {
  color: #6fba91;
}

.bloqueunoS2 p {
  width: 26vw;
  font-size: 0.8333333333333334vw;
  color: white;
  margin-left: 10.208333333333334vw;
  margin-bottom: 3vw;
}

.bloqueunoS2 a {
  font-size: 0.6770833333333334vw;
  color: white;
  padding: 1.1vw 3.9vw;
  background: linear-gradient(to left, #6fba91 100%, #76dfa6 100%);
  border-radius: 8vw;
  margin-left: 10.208333333333334vw;
}

.bloquedos {
  background-image: url("../assets/servicios_3.png");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 37vw;
  height: 45.15625vw;
}

.filados-bd {
  display: flex;
  width: 90vw;
  place-content: space-between;
  padding-top: 3.125vw;
}

.coluno-bd {
  margin-left: 11.40625vw;
}

.bloserv {
  display: flex;
  margin-bottom: 5.46875vw;
}

.filauno-bd {
  margin-left: 11.510416666666666vw;
  padding-top: 5.625vw;
}

.filauno-bd h6 {
  font-size: 0.8333333333333334vw;
  color: #6fba91;
  font-weight: 500;
  line-height: 1vw;
}

.filauno-bd h2 {
  width: 22vw;
  height: 8vw;
  font-size: 3.6458333333333335vw;
  color: #565656;
  font-weight: 800;
  line-height: 4.375vw;
}

.blotxtserv {
  margin-left: 1.8229166666666667vw;
  padding-top: 1.1vw;
}

.C1IMG1 {
  width: 4.583333333333333vw;
  height: 4.84375vw;
}

.C1T1 {
  width: 17vw;
  color: #a6a6a6;
  font-size: 1.0416666666666665vw;
  margin-bottom: 0vw;
}

.blotxtserv a {
  font-size: 0.6770833333333334vw;
  color: #9bd2c5;
  font-weight: 500;
}

.C1IMG2 {
  width: 4.895833333333333vw;
  height: 4.479166666666667vw;
}

.C1T2 {
  width: 14vw;
  color: #a6a6a6;
  font-size: 1.0416666666666665vw;
  margin-bottom: 0vw;
}

.C2IMG1 {
  width: 3.4895833333333335vw;
  height: 5.15625vw;
}

.C2T1 {
  width: 11vw;
  color: #a6a6a6;
  font-size: 1.0416666666666665vw;
  margin-bottom: 0vw;
}

.C2IMG2 {
  width: 4.166666666666666vw;
  height: 4.6875vw;
}

.C2T2 {
  width: 13vw;
  color: #a6a6a6;
  font-size: 1.0416666666666665vw;
  margin-bottom: 0vw;
}

.bloquetres {
  display: flex;
}

.coluno-bt img {
  width: 39.0625vw;
  height: auto;
}

.coldos-b3 {
  width: 100%;
  background: #f4f9fb;
  padding-top: 6.875000000000001vw;
  padding-left: 8.229166666666666vw;
}

.filauno-bt {
  margin-bottom: 6.354166666666666vw;
}

.filauno-bt h6 {
  color: #6fba91;
  font-size: 0.8333333333333334vw;
  font-weight: 500;
  line-height: 1vw;
  margin-bottom: 2.604166666666667vw;
}

.filauno-bt h2 {
  color: #565656;
  font-size: 3.6458333333333335vw;
  font-weight: 800;
  line-height: 4.375vw;
}

.filados-bt {
  display: flex;
}

.colunof2b3 {
  margin-right: 8.385416666666666vw;
}

.colunof2b3 h3 {
  color: #548eba;
  font-size: 1.5625vw;
  font-weight: 800;
  line-height: 1.875vw;
}

.colunof2b3 p {
  display: none;
}

.colunof2b3 pre {
  display: block;
  color: #a6a6a6;
  font-size: 0.78125vw;
  font-weight: 400;
  line-height: 1.4296874999999998vw;
  border: 0vw;
  background-color: #f4f9fb;
  font-family: arial;
}

.filai-f2b3 {
  margin-top: 3.229166666666667vw;
}

.coldosf2b3 h3 {
  color: #548eba;
  font-size: 1.5625vw;
  font-weight: 800;
  line-height: 1.875vw;
}

.filai-f2b3 h4 {
  color: #548eba;
  font-size: 1.5625vw;
  font-weight: 800;
  line-height: 1.875vw;
}

.coldosf2b3 p {
  width: 20vw;
  color: #a6a6a6;
  font-size: 0.78125vw;
  font-weight: 400;
  line-height: 1.4296874999999998vw;
  font-family: arial;
}

.blolista-f2b3 ul {
  width: 20vw;
  color: #a6a6a6;
  font-size: 0.78125vw;
  font-weight: 400;
  line-height: 1.4296874999999998vw;
  columns: 2;
  font-family: arial;
}

.bloquecuatro {
  background-color: #6fba91;
  background-image: url("../assets/acreditacion.jpg");
  background-repeat: no-repeat;


  padding: 29.468599033816425vw 9.66183574879227vw 104.10628019323671vw;
  background-size: 94.44444444444444vw auto;
  background-position: 100% 100%;
}

.bloquecuatro>p {
  color: #0A4CA1;
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 300;

  font-size: 4.3478260869565215vw;
  line-height: 133.333%;
  margin: 0 0 6.521739130434782vw 7.971014492753622vw
}

.bloquecuatro h3 {
  color: #FFF;
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 800;
  text-wrap: balance;

  font-size: 8.695652173913043vw;
  line-height: 127.778%;
  margin: 0 0 6.521739130434782vw 7.971014492753622vw;
}

.bloquecuatro div {
  display: grid;
  grid-auto-rows: max-content;
}

.bloquecuatro hr {
  background-color: #FFF;
  border: 0;

  width: 100%;
  height: 0.24154589371980675vw;
  margin: 7.246376811594203vw 0;
}

.bloquecuatro button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  border: 0;
  padding: 0;

  margin: 0 7.971014492753622vw;
  gap: 0 3.6231884057971016vw;
}

.bloquecuatro button p {
  color: #FFF;
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-align: start;

  font-size: 4.3478260869565215vw;
  line-height: 133.333%;
  margin: 0;
}

.bloquecuatro button img {
  width: 5.797101449275362vw;
  aspect-ratio: 1;
}

.acreditacion__logo {
  width: 65.94202898550725vw;
  aspect-ratio: 273/88;
  margin: 7.246376811594203vw 0 0 7.246376811594203vw;
}

.backdrop {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.4);
}

.filauno-b4 {
  display: flex;
}

.coluno-f1b4 {
  margin-left: 13.593749999999998vw;
  padding-top: 9.375vw;
}

.coldos-f1b4 img {
  width: 52.6vw;
  margin-left: 7.5vw;
}

.coluno-f1b4 h6 {
  color: #0a4ca1;
  font-size: 0.8333333333333334vw;
  font-weight: 500;
  line-height: 1vw;
  margin-bottom: 2.604166666666667vw;
}

.coluno-f1b4 h2 {
  width: 25vw;
  font-size: 2.864583333333333vw;
  font-weight: 800;
  line-height: 3.4375000000000004vw;
  color: white;
  margin-bottom: 3.854166666666667vw;
}

.coluno-f1b4 img {
  width: 14.21875vw;
  height: auto;
  margin-bottom: 3.0208333333333335vw;
}

.coluno-f1b4 p {
  display: block;
  width: 21.5vw;
  color: white;
  font-size: 0.78125vw;
  font-weight: 400;
  line-height: 1.4296874999999998vw;
  margin-bottom: 3.0208333333333335vw;
}

.coluno-f1b4 pre {
  display: none;
}

.coluno-f1b4 a {
  color: white;
  background: #0a4ca1;
  border-radius: 8vw;
  padding: 1.1vw 3.8vw;
  cursor: pointer;
}

.Bloque12 {
  width: 100%;
  height: 53.697916666666664vw;
  padding-top: 0vw;
  display: flex;
  background-color: #deefff;
}

.B12B1 {
  display: block;
}

.B12B1 img {
  width: 46.875vw;
  height: 53.697916666666664vw;
}

.B12_Esp {
  padding-left: 8.229166666666666vw;
  padding-top: 4.09375vw;
}

.B12_F1 {
  width: 22vw;
  height: 10vw;
  margin-bottom: 2vw;
}

.B12_F1 p {
  color: #0a4ca1;
  font-size: 2.604166666666667vw;
  line-height: 3.125vw;
  font-weight: 800;
}

.B12_F2 {
  width: 13vw;
  height: 6vw;
  margin-bottom: 0vw;
  display: inline-grid;
}

.B12_F2 label {
  font-size: 0.8333333333333334vw;
  font-weight: 800;
  line-height: 1vw;
  margin-bottom: 0.9vw;
  padding-left: 0vw;
  align-self: center;
  color: #0a4ca1;
}

.B12_F2 input {
  width: 11.458333333333332vw;
  height: 3.177083333333333vw;
  border-radius: 8vw;
  border: 1px solid #0a4ca1;
  background-color: #deefff;
  padding: 0vw 1.3vw;
}

.B12_F5 {
  width: 37vw;
  height: 17vw;
  text-align: left;
  margin-bottom: 2vw;
  padding-top: 2.864583333333333vw;
}

.B12_F5 label {
  font-size: 0.8333333333333334vw;
  font-weight: 800;
  line-height: 1vw;
  margin-bottom: 0.9vw;
  padding-left: 0vw;
  color: #0a4ca1;
}

.B12_F5 textarea {
  width: 36.5625vw;
  height: 7.03125vw;
  border-radius: 1.5625vw;
  border: 1px solid #0a4ca1;
  background-color: #deefff;
  border-color: #0a4ca1;
  resize: none;
  padding: 0.5vw 1.3vw;
}

.B12_F6 {
  text-align: center;
}

.B12_F6 button {
  width: 36.822916666666664vw;
  height: 4.739583333333333vw;
  font-size: 0.8854166666666666vw;
  border-radius: 8vw;
  margin-left: -2vw;
  color: white;
  border: 0;
  background: linear-gradient(to left, #6fba91 100%, #76dfa6 100%);
}

.B12_F7 {
  display: none;
}

.EB12_F7 {
  display: block;
  width: 32vw;
  height: 5vw;
  text-align: left;
  margin-top: 1.903382vw;
}

.Eb12_F1 {
  display: flex;
}

.EB12_F7 p {
  font-size: 0.7291666666666666vw;
  margin-right: 1vw;
}

.EB12_F7 span {
  color: #0a4ca1;
  font-weight: 800;
}

.Modales {
  position: absolute;
}

@media (min-width: 768px) {
  .bloquecuatro {
    padding: 9.322916666666666vw 52.65625000000001vw 6.875000000000001vw 12.135416666666666vw;
    background-size: 50.416666666666664vw auto;
  }

  .bloquecuatro>p {
    font-size: 0.9375vw;
    line-height: 133.333%;
    margin: 0 0 1.7187500000000002vw 1.7187500000000002vw
  }

  .bloquecuatro h3 {
    text-wrap: balance;
    font-size: 2.5vw;
    line-height: 127.083%;
    margin: 0 0 1.7187500000000002vw 1.7187500000000002vw;
  }

  .bloquecuatro hr {
    height: 0.052083333333333336vw;
    margin: 1.5104166666666667vw 0;
  }

  .bloquecuatro br {
    display: none;
  }

  .bloquecuatro button {
    margin: 0 1.7187500000000002vw;
    gap: 0 2.604166666666667vw;
  }

  .bloquecuatro button p {
    font-size: 0.9375vw;
    line-height: 133.333%;
    margin: 0;
  }

  .bloquecuatro button:hover p {
    text-decoration: underline;
  }

  .bloquecuatro button img {
    width: 1.25vw;
    aspect-ratio: 1;
  }

  .acreditacion__logo {
    width: 14.21875vw;
    aspect-ratio: 273/88;
    margin: 3.3854166666666665vw 0 0 1.5625vw;
  }
}

@media (max-width: 768px) {
  .bloqueuno {
    background-image: url("../assets/bannerunoP.png");
    background-size: contain;
    height: 110.38647342995169vw;
    padding-top: 20.294686vw;
    text-align: -webkit-center;
  }

  .bloqueuno h1 {
    width: 100%;
    height: 22vw;
    font-size: 9.66183574879227vw;
    line-height: 10.531400966183575vw;
    margin-left: 0vw;
    margin-bottom: 4.830917874396135vw;
    text-align: center;
  }

  .bloqueuno p {
    font-size: 3.864734299516908vw;
    margin-left: 0vw;
    margin-bottom: 14vw;
    width: 59vw;
    text-align: center;
  }

  .bloqueuno a {
    font-size: 3.140096618357488vw;
    padding: 4.1vw 29.9vw;
    border-radius: 8vw;
    margin-left: 0vw;
  }

  .bloqueunoS2 {
    background-image: url("../assets/servicioLP.svg");
    background-repeat: no-repeat;
    background-size: contain;
    height: 110.38647342995169vw;
    padding-top: 20.294686vw;
    text-align: -webkit-center;
  }

  .bloqueunoS2 h1 {
    width: 70%;
    height: 22vw;
    font-size: 9.66183574879227vw;
    line-height: 10.531400966183575vw;
    margin-left: 0vw;
    margin-bottom: 4.830917874396135vw;
    text-align: center;
  }

  .bloqueunoS2 p {
    width: 62vw;
    font-size: 3.864734299516908vw;
    margin-left: 0vw;
    margin-bottom: 8vw;
    text-align: center;
  }

  .bloqueunoS2 a {
    font-size: 3.140096618357488vw;
    padding: 4.1vw 29.9vw;
    border-radius: 8vw;
    margin-left: 0vw;
  }

  .bloquedos {
    background-image: url("../assets/servicios_3P.png");
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 90vw;
    height: 289.8550724637681vw;
  }

  .filauno-bd {
    margin-left: 11.510416666666666vw;
    padding-top: 11.83574879227053vw;
  }

  .filauno-bd h6 {
    height: 4vw;
    font-size: 3.3816425120772946vw;
    line-height: 1vw;
  }

  .filauno-bd h2 {
    width: 51vw;
    height: 18vw;
    font-size: 8.454106280193237vw;
    line-height: 10.144927536231885vw;
  }

  .filados-bd {
    display: block;
    width: 100%;
    padding-top: 12vw;
    padding-left: 11.510416666666666vw;
  }

  .coluno-bd {
    margin-left: 0vw;
  }

  .bloserv {
    display: flex;
    margin-bottom: 12.560386473429952vw;
  }

  .blotxtserv {
    margin-left: 1.8229166666666667vw;
    padding-top: 1.1vw;
  }

  .C1IMG1 {
    width: 16.908212560386474vw;
    height: 17.874396135265698vw;
  }

  .C1T1 {
    width: 39vw;
    font-size: 3.864734299516908vw;
    margin-bottom: 0vw;
  }

  .blotxtserv a {
    font-size: 3.140096618357488vw;
  }

  .C1IMG2 {
    width: 16.835748792270532vw;
    height: 15.403381642512079vw;
  }

  .C1T2 {
    width: 37vw;
    font-size: 3.864734299516908vw;
    margin-bottom: 0vw;
  }

  .C2IMG1 {
    width: 13.768115942028986vw;
    height: 20.342995169082126vw;
  }

  .C2T1 {
    width: 38vw;
    font-size: 3.864734299516908vw;
    margin-bottom: 0vw;
  }

  .C2IMG2 {
    width: 18.2512077294686vw;
    height: 19.32367149758454vw;
  }

  .C2T2 {
    width: 37vw;
    font-size: 3.864734299516908vw;
    margin-bottom: 0vw;
  }

  .bloquetres {
    display: block;
    height: 322.22222222222223vw;
    background-image: url("../assets/Rectangle_28P.png");
    background-repeat: no-repeat;
    background-position: bottom;
  }

  .coluno-bt {
    display: none;
  }

  .coldos-b3 {
    width: 100%;
    height: 252vw;
    padding-top: 16.908212560386474vw;
    padding-left: 15.217391304347828vw;
  }

  .filauno-bt h6 {
    height: 4vw;
    font-size: 3.3816425120772946vw;
    line-height: 1vw;
  }

  .filauno-bt h2 {
    width: 51vw;
    height: 26vw;
    font-size: 8.454106280193237vw;
    line-height: 10.144927536231885vw;
  }

  .filados-bt {
    display: block;
  }

  .colunof2b3 h3 {
    font-size: 5.797101449275362vw;
    line-height: 6.956521739130435vw;
    margin-bottom: 7.246377vw;
  }

  .colunof2b3 p {
    width: 68vw;
    display: block;
    color: #a6a6a6;
    font-size: 3.140096618357488vw;
    font-weight: 400;
    line-height: 5.746376811594202vw;
    font-family: arial;
    margin-bottom: 9.66183574879227vw;
  }

  .colunof2b3 pre {
    display: none;
  }

  .coldosf2b3 h3 {
    font-size: 5.797101449275362vw;
    line-height: 6.956521739130435vw;
    margin-bottom: 7.246377vw;
  }

  .coldosf2b3 p {
    width: 71vw;
    font-size: 3.140096618357488vw;
    line-height: 5.746376811594202vw;
    margin-bottom: 9.66183574879227vw;
  }

  .filai-f2b3 h4 {
    font-size: 5.797101449275362vw;
    line-height: 6.956521739130435vw;
    margin-bottom: 7.246377vw;
  }

  .blolista-f2b3 ul {
    width: 80vw;
    font-size: 3.140096618357488vw;
    line-height: 5.746376811594202vw;
    columns: 2;
    margin-bottom: 9.66183574879227vw;
  }

  .filauno-b4 {
    display: block;
  }

  .coluno-f1b4 {
    margin-left: 16.183574879227052vw;
    padding-top: 12.80193236714976vw;
  }

  .coluno-f1b4 h6 {
    font-size: 3.3816425120772946vw;
    line-height: 1vw;
    margin-bottom: 2.604166666666667vw;
  }

  .coluno-f1b4 h2 {
    width: 56vw;
    font-size: 8.454106280193237vw;
    line-height: 10.144927536231885vw;
    margin-bottom: 9.420289855072465vw;
  }

  .coluno-f1b4 img {
    width: 46.3768115942029vw;
    margin-bottom: 14.251207729468598vw;
  }

  .coluno-f1b4 p {
    display: none;
  }

  .coluno-f1b4 pre {
    display: block;
    width: 71vw;
    font-size: 3.140096618357488vw;
    line-height: 5.746376811594202vw;
    margin-bottom: 18.352657004830919vw;
    border: 0vw;
    background-color: #6fba91;
    color: white;
    font-weight: 400;
    font-family: Arial, Helvetica, sans-serif;
  }

  .coluno-f1b4 a {
    font-size: 3.140096618357488vw;
    border-radius: 8vw;
    padding: 5.1vw 26.8vw;
  }

  .coldos-f1b4 img {
    width: 100vw;
    margin-left: 0vw;
    margin-top: 16.425120772946862vw;
  }

  .Bloque12 {
    width: 100%;
    height: 280.19323671497585vw;
    background-color: #deefff;
    text-align: -webkit-center;
    padding-top: 14.734299516908212vw;
  }

  .B12B1 {
    display: none;
  }

  .B12_Esp {
    padding-left: 0vw;
    padding-top: 4.09375vw;
  }

  .B12_F1 {
    width: 62vw;
    height: 30vw;
    text-align: left;
    margin-bottom: 6.763285024154589vw;
    margin-left: -7vw;
  }

  .B12_F1 p {
    font-size: 8.454106280193237vw;
    line-height: 10.144927536231885vw;
    font-weight: 800;
    color: #0a4ca1;
  }

  .B12_F2 {
    width: 80vw;
    height: 24vw;
    text-align: left;
    margin-bottom: 6.521739130434782vw;
  }

  .B12_F2 label {
    color: #0a4ca1;
    font-size: 3.864734299516908vw;
    line-height: 4.63768115942029vw;
    font-weight: 800;
    margin-bottom: 4vw;
    padding-left: 5.5vw;
  }

  .B12_F2 input {
    width: 79.95169082125604vw;
    height: 14.734299516908212vw;
    border: 1px solid #0a4ca1;
    border-radius: 8vw;
    background: transparent;
    padding: 0vw 6vw;
  }

  .B12_F5 {
    width: 80vw;
    height: 55vw;
    text-align: left;
    margin-bottom: 6.521739130434782vw;
  }

  .B12_F5 label {
    color: #0a4ca1;
    font-size: 3.864734299516908vw;
    line-height: 4.63768115942029vw;
    font-weight: 800;
    margin-bottom: 4vw;
    padding-left: 5.5vw;
  }

  .B12_F5 textarea {
    width: 79.95169082125604vw;
    height: 46.13526570048309vw;
    border: 1px solid #0a4ca1;
    border-radius: 8vw;
    background: transparent;
    padding: 3vw 6vw;
  }

  .B12_F6 {
    margin-top: 2vw;
  }

  .B12_F6 button {
    width: 79.95169082125604vw;
    height: 14.734299516908212vw;
    color: white;
    border-radius: 8vw;
    border: 0;
    background: linear-gradient(to left, #6fba91 100%, #76dfa6 100%);
    font-weight: 800;
    font-size: 3.140096618357488vw;
    margin-left: 0vw;
  }

  .B12_F7 {
    display: block;
    width: 61vw;
    height: 23vw;
    text-align: left;
    margin-left: -7vw;
    margin-top: 9.903381642512077vw;
  }

  .EB12_F7 {
    display: none;
  }

  .B12_F7 p {
    font-size: 2.898550724637681vw;
  }

  .B12_F7 span {
    color: #0a4ca1;
    font-weight: 800;
  }
}
</style>