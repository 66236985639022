<template>
    <div>

        <div class="F1">
            <div class="F1C1">
                <div class="F1C1_C1">
                    <img src="../assets/llamada-telefonica-1.png">
                </div>
                <div class="F1C1_C2">
                    <p>(662) 213.23.02 y (662) 313.06.50</p>
                </div>
                <div class="F1C1_C3">
                    <a @click="showmodal = !showmodal" target="_blank"><img src="../assets/Hamburger_icon_1.png"></a>
                    <div v-if="showmodal == true" class="modalss">

                        <LaasonSM @listenModal="closeModal" @quejas="quejas" />

                    </div>
                </div>

            </div>
            <div class="F1C2">
                <div class="F1C2_C1">
                    <img src="../assets/email_1.png">
                </div>
                <div class="F1C2_C2">
                    <p>administracion@laason.mx</p>
                </div>
            </div>
            <div class="F1C3">
                <div class="F1C3_C1">
                    <p>Garmendia N°80, Col.San Benito C.P. 83190, Hermosillo, Sonora.</p>
                </div>
            </div>
            <div class="F1C4">
                <div class="F1C4_C1">
                    <a href="https://www.facebook.com/LAASONMX"><img src="../assets/facebook_2.png"></a>
                    <img src="../assets/instagram_2.png">
                </div>
            </div>
            <div class="F1C5">
                <a href="/barragan">
                    <div class="F1C5_C1">
                        <p>Cambiar a BARRAGAN</p>
                        <img src="../assets/logoMini.svg">
                    </div>
                </a>
            </div>

            <div class="wrapper" id="wrapper-mov">
                <h2>¿Tienes alguna queja o sugerencia para nuestro servicio? </h2>
                <a href="mailto:dirección@laason.mx">Envíanos un mensaje</a>
                <ol>
                    <li>Envíanos un mensaje por medio de este botón o al correo <a
                            href="mailto:dirección@laason.mx">dirección@laason.mx</a>
                    </li>
                    <li>Te confirmaremos de recibida tu queja o sugerencia, si es una queja recibe y
                        analiza las causas que la originaron si procede o no en un plazo máximo de
                        10 días hábiles.</li>
                    <li>Se tomaran las acciones pertinentes y nos comunicaremos contigo.</li>
                </ol>
                <h3>Muchas gracias por ser parte de nuestra mejora continua.</h3>

                <button @click="removeWrapper()">Salir</button>
            </div>
        </div>

        <div class="F2">
            <div class="F2C1">
                <div class="F2C1_C1">
                    <img src="../assets/logo2_2.png">
                </div>
                <div class="F2C1_C2">
                    <p>Laboratorio de Alimentos y Aguas de Sonora S.A. DE C.V.</p>
                </div>
            </div>

            <div class="F2C2">
                <div class="F2C2_C1">
                    <ul>
                        <li @click="cambiarOpcion('Home')" :class="{ selector: this.opcion == 'Home' }"><a
                                href="/laason">Inicio</a></li>
                        <li @click="cambiarOpcion('Nosotros'), redirect('nosotros')"
                            :class="{ selector: this.opcion == 'Nosotros' }">
                            <a>Nosotros</a>
                        </li>
                        <li @click="cambiarOpcion('Servicios')" :class="{ selector: this.opcion == 'Servicios' }"><a
                                href="/laason/servicios">Servicios</a></li>
                        <li @click="cambiarOpcion('Certificaciones'), redirect('acreditaciones')"
                            :class="{ selector: this.opcion == 'Certificaciones' }"><a>Acreditaciones</a>
                        </li>
                        <li @click="cambiarOpcion('Contacto'), redirect('contacto')"
                            :class="{ selector: this.opcion == 'Contacto' }">
                            <a>Contacto</a>
                        </li>
                        <li @click="cambiarOpcion('Politica')" :class="{ selector: this.opcion == 'Politica' }"><a
                                href="/laason/politica">Aviso Privacidad</a></li>
                        <li :class="{ selector: this.opcion == 'Quejas' }"><a @click="cambiarOpcion('Quejas')"
                                href="#">Quejas</a>
                            <div class="wrapper" :class="{ 'extended': opcion == 'Quejas' }">
                                <div>
                                    <h2>¿Tienes alguna queja o sugerencia para nuestro servicio? </h2>
                                    <a href="mailto:dirección@laason.mx">Envíanos un mensaje</a>
                                    <ol>
                                        <li>Envíanos un mensaje por medio de este botón o al correo <a
                                                href="mailto:dirección@laason.mx">dirección@laason.mx</a>
                                        </li>
                                        <li>Te confirmaremos de recibida tu queja o sugerencia, si es una queja recibe y
                                            analiza las causas que la originaron si procede o no en un plazo máximo de
                                            10 días hábiles.</li>
                                        <li>Se tomaran las acciones pertinentes y nos comunicaremos contigo.</li>
                                    </ol>
                                    <h3>Muchas gracias por ser parte de nuestra mejora continua.</h3>
                                    <button @click="salirQueja()">salir</button>
                                </div>
                            </div>
                        </li>
                        <li class="ClienteButton" :class="{ selector: this.opcion == 'Contacto' }"><a
                                href="/login">Acceso a
                                Clientes<img src="../assets/iconUserGreen.svg"></a></li>
                    </ul>
                </div>
            </div>

        </div>


    </div>
</template>

<script>
import Laason from "./Laason";
import ServiciosScreen from "./servicios/ServicioScreen";
import LaasonSM from './modal/LaasonSM.vue';

export default {
    name: 'HeaderSec',
    data() {
        return {
            showmodal: false,
            opcion: "Home"
        }
    },
    components: {
        ServiciosScreen,
        Laason,
        LaasonSM
    },

    created() {
        if (this.$route.name === 'servicioscreen') {
            this.opcion = 'Servicios'
        } else if (this.$route.name === 'PoliticaLaason') {
            this.opcion = 'Politica'
        }

        if (this.$route.name !== 'Homelaason') return
        const direccion = localStorage.getItem("direccion");
        if (!direccion) return;
        localStorage.removeItem("direccion");
        setTimeout(() => {
            window.location.href = `#${direccion}`
        }, 600);


    },
    computed: {
        seleccionar: function () {
            return { color: '#a94442' };
        }
    },
    methods: {
        closeModal(value) {
            this.showmodal = value;
        },
        mostrarModal(opcion) {
            this.showmodal = opcion;
        },
        cambiarOpcion(opcion) {
            this.opcion = opcion;
        },

        quejas() {
            const wrapper = document.getElementById("wrapper-mov");
            wrapper.classList.add("showing")
        },

        removeWrapper() {
            const wrapper = document.getElementById("wrapper-mov");
            wrapper.classList.remove("showing")
        },

        redirect(direccion) {
            const existencia = document.getElementById(direccion);

            if (existencia) {
                window.location.href = `#${direccion}`
            } else {
                localStorage.setItem("direccion", direccion)
                window.location.href = "/laason"
            }
        },

        salirQueja() {
            if (this.$route.name === 'servicioscreen') {
                this.opcion = "Servicios"
            } else if (this.$route.name === 'PoliticaLaason') {
                this.opcion = "Politica"
            } else {
                this.opcion = "Home"
            }
        }
    }
}
</script>

<style scoped>
.selector {
    border: 1px solid white;
    border-radius: 3vw;
    text-align: center;
    margin: 0vw !important;
}

.F1 {
    display: flex;
    width: 100%;
    height: 14.009661835748794VW;
    background-color: #548EBA;
    overflow-x: clip;
    position: relative;
}

.F1C1 {
    display: flex;
    align-items: center;
}

.F1C1_C1 {
    width: 14vw;
    text-align: center;
}

.F1C1_C1 img {
    width: 3.864734299516908VW;
    height: auto;
}

.F1C1_C2 {
    width: 73vw;
}

.F1C1_C2 p {
    color: #C2DEFF;
    font-weight: 400;
    font-size: 2.898550724637681VW;
    padding-top: 3vw;
}

.F1C1_C3 {
    display: block;
}

.F1C1_C3 img {
    width: 8.212560386473431VW;
    height: auto;
}

.F1C2 {
    display: none;
}

.F1C3 {
    display: none;
}

.F1C4 {
    display: none;
}

.F2 {
    width: 100%;
    height: 22.22222222222222VW;
    background-color: #6FBA91;
}

.F2C1 {
    display: flex;
    align-items: center;
}

.F2C1_C1 {
    margin-left: 9vw;
    margin-top: 4vw;
}

.F2C1_C1 img {
    width: 11.352657004830919VW;
    height: auto;
}

.F2C1_C2 {
    width: 55vw;
    height: 1vw;
    margin-left: 5vw;
}

.F2C1_C2 p {
    color: white;
    font-size: 3.140096618357488VW;
    line-height: 4.270531400966184VW;
    font-weight: 700;
}

.F2C2 {
    display: none;
}

.modalss {
    position: fixed;
    z-index: 2;
    padding-top: 0vw;
    left: 0;
    top: 14vw;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
}

.F1C5 {
    display: none;
}

#wrapper-mov {
    display: grid;
    position: absolute;
    z-index: 3;
    top: 100%;
    left: 0%;
    transform: translateX(100%);
    transition: transform 0.3s linear;
    background-image: linear-gradient(to bottom, #6FBA91 24%, #FFF 24%, #FFF);
    padding: 8.695652173913043vw 11.594202898550725vw 19.806763285024154vw;
}

#wrapper-mov.showing {
    transform: translateX(0%);
}


#wrapper-mov h2 {
    color: #FFF;
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 700;

    font-size: 5.797101449275362vw;
    line-height: 100%;
    margin: 0 0 3.864734299516908vw;
}

#wrapper-mov h2+a {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0A4CA1;
    color: #FFF;
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 500;

    font-size: 5.072463768115942vw;
    line-height: normal;
    height: 13.285024154589372vw;
    border-radius: 12.077294685990339vw;
    width: 100%;
    margin: 0 0 5.072463768115942vw;
}

#wrapper-mov ol {
    padding: 0 0 0 4.830917874396135vw;
}

#wrapper-mov li {
    color: #565656;
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 400;

    font-size: 4.3478260869565215vw;
    line-height: 133.333%;
}

#wrapper-mov li a {
    color: #565656;
    font-weight: 700;
}

#wrapper-mov h3 {
    color: #548EBA;
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 700;

    font-size: 4.3478260869565215vw;
    line-height: 133.333%;
    margin: 4.830917874396135vw 0 5.555555555555555vw;
}

#wrapper-mov button {
    padding: 0;
    border: 0;
    background-color: transparent;
    width: fit-content;
    margin: 0 auto;
    color: #0A4CA1;
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 700;

    font-size: 4.3478260869565215vw;
    line-height: 133.333%;
    text-decoration-line: underline;
}

@media (min-width: 768px) {

    #wrapper-mov {
        display: none;
    }


    .ClienteButton a {
        background-color: white;
        color: #6FBA91 !important;
        font-weight: 900;
        font-size: 0.6770833333333334VW;
        border-radius: 5vw;
        padding: 0.4vw 1vw;
        text-decoration: none;
    }

    .ClienteButton img {
        width: 0.8vw;
        height: auto;
        margin-left: 0.5vw;
        padding-bottom: 0.15vw;
    }

    .F1 {
        width: 100%;
        height: 3.125VW;
    }


    .F1C1_C1 img {
        width: 0.9375VW;
        height: auto;
    }

    .F1C1_C2 p {
        font-size: 0.6770833333333334VW;
        padding-top: 1vw;
    }

    .F1C1_C3 {
        display: none;
    }

    .F1C2 {
        display: flex;
        align-items: center;
        margin-right: 34.729166666666668VW;
    }

    .F1C1_C1 {
        width: 1vw;
        text-align: center;
        margin-left: 4.322916666666667VW;
        margin-right: 1vw;
    }

    .F1C1_C2 {
        width: 12.4vw;
    }

    .F1C2_C1 {
        margin-right: 1vw;
    }

    .F1C2_C1 img {
        width: 0.9375VW;
        height: auto;
    }

    .F1C2_C2 p {
        font-size: 0.6770833333333334VW;
        padding-top: 1vw;
        color: #C2DEFF;
        font-weight: 400;
    }


    .F1C3_C1 p {
        font-size: 0.6770833333333334VW;
        padding-top: 1vw;
        color: #C2DEFF;
        font-weight: 400;
    }


    .F2 {
        width: 100%;
        height: 6.09375VW;
        display: flex;
    }

    .F2C1 {
        margin-right: 21vw;
    }

    .F2C1_C1 {
        margin-left: 5.260416666666667VW;
        margin-top: 0.1VW;
        margin-right: 1.6145833333333335VW;
    }

    .F2C1_C1 img {
        width: 3.4375000000000004VW;
        height: auto;
    }

    .F2C1_C2 {
        width: 12vw;
        height: 1.5vw;
        margin-left: 0vw;
        text-transform: uppercase;
    }

    .F2C1_C2 p {
        font-size: 0.6770833333333334VW;
        line-height: 0.9208333333333334VW;
    }

    .F2C2 {
        display: flex;
        align-items: center;
    }

    .F2C2_C1 ul {
        display: flex;
        color: white;
        list-style: none;
        font-weight: 500;
        font-size: 0.6770833333333334VW;
        margin-bottom: 0vw;
    }

    .F2C2_C1>ul>li {
        cursor: pointer;
        align-self: center;
    }

    .F2C2_C1>ul>li:first-of-type {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .F2C2_C1>ul>li:first-of-type:hover {
        text-decoration: underline;
    }

    .F2C2_C1>ul>li:not(:last-of-type) {
        width: 5.989583333333334vw;
        height: 1.8229166666666667vw;
    }

    .F2C2_C1>ul>li:last-of-type {
        margin: 0 0 0 0.78125vw;
    }

    .F2C2_C1>ul>li a {
        color: white;
        white-space: nowrap;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
    }


    .F2C2_C1 ul>li {
        position: relative;
    }

    .wrapper {
        box-sizing: border-box;
        display: grid;
        grid-template-rows: 0fr;
        transition: grid-template-rows 0.3s linear;
        position: absolute;
        background-image: linear-gradient(to bottom, #6FBA91 32%, #FFF 32%, #FFF);
        z-index: 4;

        width: 20.885416666666668vw;
        border-radius: 0 0 1.0416666666666665vw 1.0416666666666665vw;
        top: 102%;
        left: -8.854166666666668vw;
        box-shadow: 0 0.20833333333333334vw 0.5208333333333333vw 0 rgba(0, 0, 0, 0.10) inset;
    }

    .wrapper.extended {
        grid-template-rows: 1fr;
    }

    .wrapper * {
        box-sizing: border-box;
    }

    .wrapper>div {
        overflow: hidden;
        padding: 0 2.1875vw;
    }

    .wrapper h2 {
        color: #FFF;
        font-family: Arial, Helvetica, sans-serif;
        font-style: normal;
        font-weight: 700;
        text-align: start;

        font-size: 1.25vw;
        line-height: 100%;
        margin: 3.90625vw 0 1.1979166666666667vw;
    }

    .wrapper h2+a {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #0A4CA1;
        color: #FFF;
        font-family: Arial, Helvetica, sans-serif;
        font-style: normal;
        font-weight: 500;

        font-size: 0.9895833333333333vw;
        line-height: normal;
        width: 100%;
        height: 2.7604166666666665vw !important;
        border-radius: 2.604166666666667vw;
        margin: 0 0 1.1979166666666667vw;
    }

    .wrapper ol {
        padding: 0 0 0 1.0416666666666665vw;
    }

    .wrapper li {
        margin: 0;
        text-align: start;
        color: #565656;
        font-family: Arial, Helvetica, sans-serif;
        font-style: normal;
        font-weight: 400;

        font-size: 0.8333333333333334vw;
        line-height: 150%;
    }

    .wrapper li a {
        color: #565656 !important;
        font-family: Arial, Helvetica, sans-serif;
        font-style: normal;
        font-weight: 700;
        display: inline !important;
        font-size: 0.8333333333333334vw;
        line-height: 1.25vw;
    }

    .wrapper h3 {
        color: #548EBA;
        font-family: Arial, Helvetica, sans-serif;
        font-style: normal;
        font-weight: 700;
        text-align: start;

        font-size: 0.8333333333333334vw;
        line-height: 1.25vw;
        margin: 1.6666666666666667vw 0 1.5625vw;
    }

    .wrapper button {
        border: 0;
        padding: 0;
        background-color: transparent;
        color: #0A4CA1;
        font-family: Arial, Helvetica, sans-serif;
        font-style: normal;
        font-weight: 700;

        font-size: 0.7291666666666666vw;
        line-height: 171.429%;
        text-decoration-line: underline;
        margin: 0 0 0.9895833333333333vw;
    }


    .F2C2_C1 a {
        color: white;
        white-space: nowrap;
    }

    .F1C3 {
        display: flex;
        align-items: center;
        margin-right: 3.958333333333333VW;
    }

    .F1C4 {
        display: flex;
        align-items: center;
        margin-right: 4vw;
    }

    .F1C4_C1 {
        width: 3vw;
        justify-content: space-between;
    }

    .F1C4_C1 img {
        width: auto;
        height: 1.25VW;
        margin-right: 0.2vw;
        cursor: pointer;
    }



    .F1C5 {
        display: flex;
        align-items: center;
        background-color: white;
        cursor: pointer;
    }

    .F1C5 a {
        width: 100%;
        height: 100%;
        padding: 1vw 0vw;
    }

    .F1C5_C1 {
        display: flex;
        width: 12.1875VW;
        justify-content: space-between;
        padding: 0vw 2vw;
    }

    .F1C5_C1 p {
        color: #6FBA91;
        font-size: 0.6770833333333334VW;
        font-weight: 900;
        margin: 0vw;
        align-self: center;
    }

    .F1C5_C1 img {
        width: auto;
        height: 1.25VW;
        margin-right: 0.2vw;
        cursor: pointer;
    }


}
</style>
