<template>
    <div class="modal-content">
        <p @click="closeModal()" class="close"> &times;</p>
        <template v-for="(element) in acreditacion">
            <img :src="element">
            <hr>
        </template>
    </div>
</template>

<script>


export default {
    props: {
        acreditacion: {
            type: Array, // Define el tipo de la propiedad como Array
            required: true, // Indica que es una propiedad obligatoria
            default: () => [] // Proporciona un valor por defecto como un array vacío
        }
    },

    name: 'LaasonModal',
    data() {
        return {
            showmodal: false,
            modalopc: ""
        }
    },
    methods: {
        closeModal: function () {
            this.$emit('listenModal', this.showmodal)
        }
    }
}
</script>

<style scoped>
.modal-content {
    background-color: #fefefe;
    margin: -8% auto;
    /* 15% from the top and centered */
    padding: 1.5vw;
    border: 1px solid #888;
    width: 48%;
    /* Could be more or less, depending on screen size */
}

.modal-content img {
    width: 100%;
}

.close {
    color: #aaa;
    float: right;
    font-size: 1.7vw;
    font-weight: bold;
    margin: 0 0 0 auto;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.ModalLaason {
    position: absolute;
    margin: -49vw 19vw;
}

hr {
    width: 100%;
    background-color: #888;

    height: 0.052083333333333336vw;
}

hr:last-of-type {
    display: none;
}

@media (max-width: 768px) {
    .modal-content {
        width: 80%;
    }

    hr {
        height: 0.24154589371980675vw;
    }
}
</style>