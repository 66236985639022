<template>
    <div>
        <HeaderSec />
        <div class="content">

            <div class="Bloque1">
                <div class="EF1_B1">
                    <div class="EB1_F1">
                        <p>Servicios</p>
                    </div>
                    <div class="EB1_F2">
                        <p>Nuestros filtros están diseñados, construidos y certificados según las normas sanitarias.</p>
                    </div>
                </div>
                <div class="EF1_B2">
                    <img src="../../assets/servicios2_2.png">
                </div>
                <div class="B1_F1">
                    <p>Servicios</p>
                </div>
                <div class="B1_F2">
                    <p>Nuestros filtros están diseñados, construidos y certificados según las normas sanitarias.</p>
                </div>
            </div>

            <div id="analisis-microbiologico" class="Bloque2">
                <div class="B2B1">
                    <img src="../../assets/Rectangle_17P.png">
                </div>
                <div>
                    <div class="B2_F1">
                        <div class="B2_C1">
                            <img src="../../assets/icouno.png">
                        </div>
                        <div class="B2_C2">
                            <p>Análisis microbiológico de alimentos, aguas, superficies y ambientes</p>
                        </div>
                    </div>
                    <div class="B2_F2">
                        <p>Análisis microbiológicos y control de calidad para materia prima, producto terminado,
                            bebidas, agua, superficies vivas e inertes así como ambientes en base a normativas
                            nacionales e internacionales.</p>
                    </div>
                </div>
            </div>

            <div class="Bloque3"></div>

            <div id="analisis-fisioquimic-de-agua-potable" class="Bloque4">
                <div>
                    <div class="B4_F1">
                        <div class="B4_C1">
                            <img src="../../assets/icodos.png">
                        </div>
                        <div class="B4_C2">
                            <p>Análisis fisicoquímicos de agua potable y residual</p>
                        </div>
                    </div>
                    <div class="B4_F2">
                        <p>Control de calidad del agua en según  las normas vigentes</p>
                    </div>
                </div>
                <div class="B4B2">
                    <img src="../../assets/Rectangle_18P.png">
                </div>
            </div>

            <div class="Bloque5"></div>

            <div id="etiquetado-nutrimental" class="Bloque6">
                <div class="B6B1">
                    <img src="../../assets/Rectangle_19P.png">
                </div>
                <div>
                    <div class="B6_F1">
                        <div class="B6_C1">
                            <img src="../../assets/icotres.png">
                        </div>
                        <div class="B6_C2">
                            <p>Etiquetado nutrimental México y FDA- California</p>
                            <pre>Etiquetado
nutrimental
México y
FDA- California</pre>
                        </div>
                    </div>
                    <div class="B6_F2">
                        <p>Análisis proximal para elaboración de etiquetas para alimentos de consumo México y productos
                            de exportación según lineamientos FDA y California.
                            Etiquetado según nuevos lineamientos.</p>
                        <pre>  Análisis proximal para elaboración de etiquetas
para alimentos de consumo México y productos de
  exportación según lineamientos FDA y California.
 Etiquetado según nuevos lineamientos.</pre>
                    </div>
                </div>
            </div>

            <div class="Bloque7"></div>

            <div id="analisis-clinicos-para-alimentos" class="Bloque8">

                <div>
                    <div class="B8_F1">
                        <div class="B8_C1">
                            <img src="../../assets/icocuatro.png">
                        </div>
                        <div class="B8_C2">
                            <p>Análisis clínicos para manipuladores de alimentos</p>
                        </div>
                    </div>
                    <div class="B8_F2">
                        <p>Análisis clínicos para manipuladores según normativas a cumplir:</p>
                        <ul>
                            <li>Exudado Faríngeo</li>
                            <li>Coproparasitoscopico</li>
                            <li>Coprocultivo</li>
                            <li>Reacciones febriles</li>
                            <li>Lavado de manos</li>
                            <li>Cultivo de uñas</li>
                            <li>Antidoping</li>
                        </ul>
                    </div>
                </div>

                <div class="B8B2">
                    <img src="../../assets/Rectangle_20P.png">
                </div>

            </div>

            <div class="Bloque9"></div>
            <div id="otros-analisis" class="Bloque10">
                <div class="B10B1">
                    <img src="../../assets/Rectangle_21P.png">
                </div>
                <div>
                    <div class="B10_F1">
                        <p>Otros análisis</p>
                    </div>
                    <div class="B10_F2">
                        <ul>
                            <li>Vida de anaquel</li>
                            <li>Reto Microbiano</li>
                        </ul>
                        <ul>
                            <li>Sustancias tóxicas</li>
                            <li>Entre otros</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="Bloque11"></div>

            <div class="Bloque12" id="contacto">
                <div class="B12B1">
                    <img src="../../assets/Rectangle_25P.png">
                </div>

                <div class="B12_Esp">
                    <div class="B12_F1">
                        <p>¿Dudas? Mándanos un mensaje.</p>
                    </div>
                    <form @submit.prevent="
                        submit(
                            name,
                            phone,
                            mail,
                            message
                        )
                        ">
                        <div class="B12_F2">
                            <label for="Nombre">Nombre</label>
                            <input name="Nombre">
                        </div>
                        <div class="B12_F2">
                            <label for="Telefono">Télefono</label>
                            <input name="Telefono">
                        </div>
                        <div class="B12_F2">
                            <label for="Correo">Correo</label>
                            <input name="Correo">
                        </div>
                        <div class="B12_F5">
                            <label for="Mensaje">Mensaje</label>
                            <textarea name="Mensaje"></textarea>
                        </div>
                        <div class="B12_F6">
                            <button>Enviar</button>
                        </div>

                    </form>
                    <div class="B12_F7">
                        <p><span>Tels.</span> (662) 213.23.02 y (662) 313.06.50.</p>
                        <p><span>Mail.</span> administracion@laason.mx</p>
                        <p><span>Dirección:</span> Garmendia N°80, Col.San Benito C.P. 83190. Hermosillo, Sonora.</p>
                    </div>
                    <div class="EB12_F7">
                        <div class="Eb12_F1">
                            <p><span>Tels.</span> (662) 213.23.02 y (662) 313.06.50.</p>
                            <p><span>|</span></p>
                            <p><span>Mail.</span> administracion@laason.mx</p>
                        </div>
                        <p><span>Dirección:</span> Garmendia N°80, Col.San Benito C.P. 83190. Hermosillo, Sonora.</p>
                    </div>
                </div>

            </div>
            <div class="Bloque13"></div>
            <div class="Bloque14">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3486.378370506103!2d-110.95978618472249!3d29.094508882237868!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86ce850526e3f02f%3A0xbbb1453832ac2b5b!2sLaboratorio%20de%20Alimentos%20y%20Aguas%20de%20Sonora%20S.A.%20de%20C.V.!5e0!3m2!1ses!2smx!4v1638914584188!5m2!1ses!2smx"
                    width="100%" height="400" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
            </div>
        </div>
        <FooterSec :name="this.opc" />
    </div>
</template>


<script>
import HeaderSec from '../HeaderSec.vue';
import FooterSec from '../FooterSec.vue';

export default {
    name: 'servicioscreen',
    data() {
        return {
            opc: "laasonService",
            name: "",
            phone: "",
            mail: "",
            message: ""

        }
    },
    components: {
        HeaderSec,
        FooterSec
    },

    created() {
        const direccion = localStorage.getItem("direccion");

        if (direccion) {
            localStorage.removeItem("direccion");
            setTimeout(() => {
                window.location.href = `#${direccion}`
            }, 600);
        }

    },

}
</script>

<style scoped>
/*Estos estilos comienzan con Movil y en mediaquery estan los estilos de escritorio*/

.Bloque1 {
    display: block;
    width: 100%;
    height: 111.11111111111111VW;
    background-image: url("../../assets/servicios2_2P.png");
    text-align: -webkit-center;
    padding-top: 34vw;
}

.EF1_B1 {
    display: none;
}

.EF1_B2 {
    display: none;
}

.B1_F1 {
    display: block;
}

.B1_F2 {
    width: 47vw;
    height: 13vw;
    display: block;
}

.B1_F1 p {
    color: #6FBA91;
    font-size: 12.077294685990339VW;
    font-weight: 800;
}

.B1_F2 p {
    color: #FFFFFF;
    font-size: 3.140096618357488VW;
    line-height: 18.66px;
    letter-spacing: 1.5%;
}

.Bloque2 {
    width: 100%;
    height: 112.31884057971016VW;
    text-align: -webkit-center;
}

.B2B1 {
    display: none;
}

.B2_F1 {
    display: flex;
    justify-content: center;
    margin-top: 21.73913043478261VW;
}

.B2_C2 {
    width: 46vw;
    text-align: left;
    ;
}

.B2_C1 img {
    width: 15.942028985507244VW;
    height: 16.908212560386474VW;
    margin-right: 5.797101449275362VW
}

.B2_C2 p {
    font-size: 5.797101449275362VW;
    color: #548EBA;
    font-weight: 800;
}

.B2_F2 {
    width: 65vw;
    height: 27vw;
    margin-top: 14.492753623188406VW;
}

.B2_F2 p {
    font-size: 3.140096618357488VW;
    font-weight: 400;
    line-height: 5.589371980676329VW;
    letter-spacing: 1.5%;
    text-align: center;
    color: #A6A6A6;
}

.Bloque3 {
    width: 100%;
    height: 62.31884057971014VW;
    background-image: url("../../assets/Rectangle_17P.png");
    display: block;
}

.Bloque4 {
    width: 100%;
    height: 79.22705314009661VW;
    text-align: -webkit-center;
}

.B4B2 {
    display: none;
}

.B4_F1 {
    display: flex;
    justify-content: center;
    margin-top: 18.357487922705314VW;
}

.B4_C2 {
    width: 44vw;
    text-align: left;
    ;
}

.B4_C1 img {
    width: 15.442028985507248VW;
    height: 15.077294685990339VW;
    margin-right: 5.797101449275362VW
}

.B4_C2 p {
    font-size: 5.797101449275362VW;
    color: #548EBA;
    font-weight: 800;
}

.B4_F2 {
    width: 54vw;
    height: 10vw;
    margin-top: 7.729468599033816VW;
}

.B4_F2 p {
    font-size: 3.140096618357488VW;
    font-weight: 400;
    line-height: 5.589371980676329VW;
    letter-spacing: 1.5%;
    text-align: center;
    color: #A6A6A6;
}

.Bloque5 {
    width: 100%;
    height: 62.31884057971014VW;
    background-image: url("../../assets/Rectangle_18P.png");
}

.Bloque6 {
    width: 100%;
    height: 94.92753623188406VW;
    text-align: -webkit-center;
}

.B6B1 {
    display: none;
}

.B6_F1 {
    display: flex;
    justify-content: center;
    margin-top: 18.357487922705314VW;
}

.B6_C2 {
    width: 53vw;
    text-align: left;
    ;
}

.B6_C1 img {
    width: 14VW;
    height: 20.77294685990338VW;
    margin-right: 5.797101449275362VW
}

.B6_C2 p {
    display: none
}

.B6_C2 pre {
    display: block;
    font-size: 5.797101449275362VW;
    color: #548EBA;
    font-weight: 800;
    background-color: white;
    border: 0;
}

.B6_F2 {
    width: 90.8vw;
    height: 27vw;
    margin-top: 7.729468599033816VW;
}

.B6_F2 p {
    display: none;
}

.B6_F2 pre {
    display: block;
    background-color: white;
    border: 0;
    font-size: 3.140096618357488VW;
    font-weight: 400;
    line-height: 5.589371980676329VW;
    letter-spacing: 1.5%;
    text-align: center;
    color: #A6A6A6;
}

.Bloque7 {
    width: 100%;
    height: 62.31884057971014VW;
    background-image: url("../../assets/Rectangle_19P.png");
    display: block;
}

.Bloque8 {
    width: 100%;
    height: 136.23188405797103VW;
    text-align: -webkit-center;
}

.B8B2 {
    display: none;
}

.B8_F1 {
    display: flex;
    justify-content: center;
    margin-top: 21.73913043478261VW;
}

.B8_C2 {
    width: 33vw;
    text-align: left;
    ;
}

.B8_C1 img {
    width: 20.396135265700483VW;
    height: 22.946859903381643VW;
    margin-right: 5.797101449275362VW
}

.B8_C2 p {
    font-size: 5.797101449275362VW;
    color: #548EBA;
    font-weight: 800;
}

.B8_F2 {
    width: 60vw;
    height: 52vw;
    margin-top: 14.492753623188406VW;
    text-align: left;
    ;
}

.B8_F2 p {
    font-size: 3.140096618357488VW;
    font-weight: 400;
    line-height: 5.589371980676329VW;
    letter-spacing: 1.5%;
    text-align: left;
    color: #A6A6A6;
    margin-bottom: 5vw;
}

.B8_F2 ul {
    color: #A6A6A6;
    font-size: 3.140096618357488VW;
    font-weight: 400;
    line-height: 5.589371980676329VW;
    letter-spacing: 1.5%;
    padding-left: 6vw;
}

.Bloque9 {
    display: block;
    width: 100%;
    height: 62.31884057971014VW;
    background-image: url("../../assets/Rectangle_20P.png");
}

.Bloque10 {
    width: 100%;
    height: 59.17874396135265VW;
    text-align: -webkit-center;
}

.B10B1 {
    display: none;
}

.B10_F1 {
    width: 67.6328502415459VW;
    height: 7.004830917874397VW;
    margin-top: 15.217391304347828VW;
}

.B10_F1 p {
    font-size: 5.797101449275362VW;
    color: #548EBA;
    font-weight: 800;
    text-align: left;
}

.B10_F2 {
    display: flex;
    justify-content: center;
    margin-top: 11.11111111111111VW;
}

.B10_F2 ul {
    color: #A6A6A6;
    font-size: 3.140096618357488VW;
    font-weight: 400;
    line-height: 5.589371980676329VW;
    letter-spacing: 1.5%;
    text-align: left;
    margin-right: 7vw;
}

.Bloque11 {
    display: block;
    width: 100%;
    height: 62.31884057971014VW;
    background-image: url("../../assets/Rectangle_21P.png");
}

.Bloque12 {
    width: 100%;
    height: 280.19323671497585VW;
    background-color: #DEEFFF;
    text-align: -webkit-center;
    padding-top: 14.734299516908212VW;
}

.B12B1 {
    display: none;
}

.B12_F1 {
    width: 62vw;
    height: 30vw;
    text-align: left;
    margin-bottom: 6.763285024154589VW;
    margin-left: -7vw;
}

.B12_F1 p {
    font-size: 8.454106280193237VW;
    line-height: 10.144927536231885VW;
    font-weight: 800;
    color: #0A4CA1;
}

.B12_F2 {
    width: 80vw;
    height: 24vw;
    text-align: left;
    margin-bottom: 6.521739130434782VW;
}

.B12_F2 label {
    color: #0A4CA1;
    font-size: 3.864734299516908VW;
    line-height: 4.63768115942029VW;
    font-weight: 800;
    margin-bottom: 4vw;
    padding-left: 5.5vw;
}

.B12_F2 input {
    width: 79.95169082125604VW;
    height: 14.734299516908212VW;
    border: 1px solid #0A4CA1;
    border-radius: 8vw;
    background: transparent;
    padding: 0vw 6vw;
}


.B12_F5 {
    width: 80vw;
    height: 55vw;
    text-align: left;
    margin-bottom: 6.521739130434782VW;
}

.B12_F5 label {
    color: #0A4CA1;
    font-size: 3.864734299516908VW;
    line-height: 4.63768115942029VW;
    font-weight: 800;
    margin-bottom: 4vw;
    padding-left: 5.5vw;
}

.B12_F5 textarea {
    width: 79.95169082125604VW;
    height: 46.13526570048309VW;
    border: 1px solid #0A4CA1;
    border-radius: 8vw;
    background: transparent;
    padding: 3vw 6vw;
}

.B12_F6 {
    margin-top: 2vw;
}

.B12_F6 button {
    width: 79.95169082125604VW;
    height: 14.734299516908212VW;
    color: white;
    border-radius: 8vw;
    border: 0;
    background: linear-gradient(to left, #6FBA91 100%, #76DFA6 100%);
    font-weight: 800;
}

.B12_F7 {
    display: block;
    width: 61vw;
    height: 23vw;
    text-align: left;
    margin-left: -7vw;
    margin-top: 9.903381642512077VW;
}

.EB12_F7 {
    display: none;
}

.B12_F7 p {
    font-size: 2.898550724637681VW;
}

.B12_F7 span {
    color: #0A4CA1;
    font-weight: 800;
}

.Bloque13 {
    display: block;
    width: 100%;
    height: 82.85024154589372VW;
    background-image: url("../../assets/Rectangle_25P.png");
}

.Bloque14 iframe {
    width: 100%;
    height: 74.63768115942028VW;
}

@media (min-width: 768px) {

    .Bloque1 {
        background: none;
        display: flex;
        height: 27.8125VW;
        width: 100%;
        padding-top: 0vw;
    }

    .B1_F1 {
        display: none;
    }

    .B1_F2 {
        display: none;
    }

    .EF1_B1 {
        display: block;
        width: 41.09375VW;
        height: auto;
        padding-top: 10.052083333333334VW;
    }

    .EF1_B2 {
        display: block;
    }

    .EF1_B2 img {
        width: 58.90625000000001VW;
        height: auto;
    }

    .EB1_F1 p {
        font-size: 3.90625VW;
        color: #6FBA91;
        line-height: 4.6875VW;
        font-weight: 800;
        width: 23vw;
        text-align: left;
    }

    .EB1_F2 {
        width: 23vw;
        height: 2vw;
    }

    .EB1_F2 p {
        font-size: 0.78125VW;
        color: #A6A6A6;
        line-height: 1.1213541666666667VW;
        font-weight: 400;
        text-align: left;
    }


    .Bloque2 {
        display: flex;
        height: 37.135416666666664VW;
        width: 100%
    }

    .B2_F1 {
        margin-left: 8.802083333333334VW;
        margin-top: 11.145833333333334VW;
    }

    .B2B1 {
        display: block;
    }

    .B2B1 img {
        width: 50VW;
        height: 37.135416666666664VW;
    }

    .B2_C1 img {
        width: 3.958333333333333VW;
        height: 4.84375VW;
        margin-right: 2.2916666666666665VW;
    }

    .B2_C2 {
        width: 21vw;
    }

    .B2_C2 p {
        font-size: 1.5625VW;
        line-height: 1.875VW;
    }

    .B2_F2 {
        width: 16vw;
        height: 8vw;
        margin-top: 2.2916666666666665VW;
        margin-left: 10vw;
    }

    .B2_F2 p {
        font-size: 0.8333333333333334VW;
        line-height: 1.4833333333333334VW;
        text-align: left;
    }


    .Bloque3 {
        display: none;
    }

    .Bloque4 {
        display: flex;
        width: 100%;
        height: 37.135416666666664VW;
        text-align: -webkit-center;
    }

    .B4_F1 {
        margin-top: 13.229166666666666VW;
        width: 50VW;
    }

    .B4_C1 img {
        width: 4.427083333333334VW;
        height: 4.322916666666667VW;
        margin-right: 1.25VW;
    }

    .B4_C2 {
        width: 16vw;
        text-align: left;
    }

    .B4_C2 p {
        font-size: 1.5625VW;
        color: #548EBA;
        font-weight: 800;
    }

    .B4_F2 {
        width: 16vw;
        height: 3vw;
        margin-top: 2.2395833333333335VW;
        margin-left: 6vw;
    }

    .B4_F2 p {
        font-size: 0.8333333333333334VW;
        line-height: 1.4833333333333334VW;
        text-align: left;
    }

    .B4B2 {
        display: block;
    }

    .B4B2 img {
        width: 48.7VW;
        height: 37.135416666666664VW;
    }

    .Bloque5 {
        display: none;
    }

    .Bloque6 {
        display: flex;
        width: 100%;
        height: 37.135416666666664VW;
    }

    .B6B1 {
        display: block;
    }

    .B6B1 img {
        width: 50VW;
        height: 37.135416666666664VW;
    }

    .B6_F1 {
        width: 48.7VW;
        margin-top: 12.291666666666666VW;
    }

    .B6_C1 img {
        width: 2.604166666666667VW;
        height: 4.6875VW;
        margin-right: 2.34375VW;
    }

    .B6_C2 {
        width: 17vw;
    }

    .B6_C2 p {
        display: block;
        font-size: 1.5625VW;
        color: #548EBA;
        font-weight: 800;
        background-color: white;
        border: 0;
    }

    .B6_C2 pre {
        display: none;
    }

    .B6_F2 {
        width: 19vw;
        height: 25vw;
        margin-top: 1.6666666666666667VW;
    }

    .B6_F2 p {
        display: block;
        background-color: white;
        border: 0;
        font-size: 0.8333333333333334VW;
        font-weight: 400;
        line-height: 1.4833333333333334VW;
        letter-spacing: 1.5%;
        text-align: left;
        color: #A6A6A6;
    }

    .B6_F2 pre {
        display: none;
    }


    .Bloque7 {
        display: none;
    }


    .Bloque8 {
        width: 100%;
        height: 37.135416666666664VW;
        text-align: -webkit-center;
        display: flex;
    }

    .B8_F1 {
        display: flex;
        justify-content: center;
        margin-top: 10.885416666666666VW;
        width: 50vw
    }

    .B8_C1 img {
        width: 4.166666666666666VW;
        height: 4.6875VW;
        margin-right: 5.797101449275362VW;
    }

    .B8_C2 {
        width: 20vw;
        padding-top: 1vw;
    }

    .B8_C2 p {
        font-size: 1.5625VW;
        margin: 0;
    }

    .B8_F2 {
        width: 15vw;
        height: 14vw;
        margin-top: 3.125VW;
    }

    .B8_F2 p {
        font-size: 0.8333333333333334VW;
        line-height: 1.4833333333333334VW;
        letter-spacing: 1.5%;
        text-align: left;
        color: #A6A6A6;
        margin-bottom: 2vw;
    }

    .B8_F2 ul {
        width: 26vw;
        font-size: 0.8333333333333334VW;
        line-height: 1.4833333333333334VW;
        padding-left: 1.5vw;
        columns: 2;
    }

    .B8B2 {
        display: block;
    }

    .B8B2 img {
        width: 48.7vw;
        height: 37.135416666666664VW;
    }



    .Bloque9 {
        display: none;
    }

    .Bloque10 {
        width: 100%;
        height: 37.135416666666664VW;
        text-align: -webkit-center;
        display: flex;
    }

    .B10B1 {
        display: block;
    }

    .B10B1 img {
        width: 50vw;
        height: 37.135416666666664VW;
    }

    .B10_F1 {
        width: 48.7VW;
        height: 2VW;
        padding-left: 15vw;
        margin-top: 15.416666666666668VW;
    }

    .B10_F1 p {
        font-size: 1.5625VW;
    }

    .B10_F2 {
        display: flex;
        justify-content: center;
        margin-top: 2.083333333333333VW;
    }

    .B10_F2 ul {
        font-size: 0.8333333333333334VW;
        line-height: 1.2750000000000001VW;
        margin-right: 2vw;
    }


    .Bloque11 {
        display: none;
    }


    .Bloque12 {
        width: 100%;
        height: 53.697916666666664VW;
        padding-top: 0VW;
        display: flex;
    }

    .B12B1 {
        display: block;
    }

    .B12B1 img {
        width: 46.875VW;
        height: 53.697916666666664VW;
    }

    .B12_Esp {
        padding-left: 8.229166666666666VW;
        padding-top: 4.09375VW;
    }

    .B12_F1 {
        width: 28vw;
        height: 10vw;
        margin-bottom: 2VW;
        margin-right: -8vw;
        margin-left: -19vw;
    }

    .B12_F1 p {
        font-size: 2.604166666666667VW;
        line-height: 3.125VW;
    }

    .B12_F2 {
        width: 13vw;
        height: 6vw;
        margin-bottom: 0vw;
        display: inline-grid;
    }

    .B12_F2 label {
        font-size: 0.8333333333333334VW;
        line-height: 1VW;
        margin-bottom: .9vw;
        padding-left: 0vw;
        align-self: center;
    }

    .B12_F2 input {
        width: 11.458333333333332VW;
        height: 3.177083333333333VW;
        border-radius: 8vw;
        padding: 0vw 1.3vw;
    }

    .B12_F5 {
        width: 37vw;
        height: 17vw;
        text-align: left;
        margin-bottom: 2VW;
        padding-top: 2.864583333333333VW;
        margin-left: -2vw;
    }

    .B12_F5 label {
        font-size: 0.8333333333333334VW;
        line-height: 1VW;
        margin-bottom: .9vw;
        padding-left: 0vw;
    }

    .B12_F5 textarea {
        width: 36.5625VW;
        height: 7.03125VW;
        border-radius: 1.5625VW;
        padding: 0.5vw 1.3vw;
    }

    .B12_F6 button {
        width: 36.822916666666664VW;
        height: 4.739583333333333VW;
        border-radius: 8vw;
        margin-left: -2vw;
    }

    .B12_F7 {
        display: none;
    }

    .EB12_F7 {
        display: block;
        width: 32vw;
        height: 5vw;
        text-align: left;
        margin-top: 1.903382VW;
        margin-left: -7vw;
    }

    .Eb12_F1 {
        display: flex;
    }

    .EB12_F7 p {
        font-size: 0.7291666666666666VW;
        margin-right: 1vw;
    }

    .EB12_F7 span {
        color: #0A4CA1;
        font-weight: 800;
    }


    .Bloque13 {
        display: none;
    }

    .Bloque14 iframe {
        width: 100%;
        height: 21.145833333333332VW;
    }

}
</style>